import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { GenericExtender } from '@interfaces/generic-extender.interface';
import { IFileList, IGenericName, INames, ITimeUnit } from 'app/repository/models/product.models';

import { checkHasChangedFormControlValidator } from '../../../../purchasing-manager/components/purchasing-data/services/data.service';

export type MappedPurchasingDataType = ReturnType<typeof purchasingDataPostMapper>;

/**
 *
 * @param {Partial<PurchasingDataType>} value value
 * @returns {MappedPurchasingDataType} mapped mapped
 */
export function purchasingDataPostMapper(value: Partial<PurchasingDataType>) {
  return {
    genericNameClassificationId: value.genericNameClassificationId,
    descriptions: [
      {
        localeLanguage: 'es-ES',
        name: value.descriptionES
      },
      {
        localeLanguage: 'pt-PT',
        name: value.descriptionPT
      }
    ],
    brand: value.brand,
    model: value.model,
    isLpcExclusive: value.isLpcExclusive,
    isKit: value.isKit,
    isDangerous: value.isDangerous,
    taricCode: value.taricCode,
    naceCode: value.naceCode,
    plannedUsefulLife: value.plannedUsefulLife,
    plannedUsefulLifeType: value.plannedUsefulLifeType
  };
}

export type PurchasingDataType = {
  genericNameClassificationId: GenericExtender<IGenericName> | null;
  descriptionES: string | null;
  descriptionPT: string | null;
  brand: string | null;
  model: string | null;
  isLpcExclusive: boolean | null;
  isDangerous: boolean | null;
  isKit: boolean | null;
  life: string | null;
  taricCode: string | null;
  naceCode: string | null;
  plannedUsefulLife: number | null;
  plannedUsefulLifeType: INames[] | null;
};

/**
 * DataService
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {
  formGroup!: ReturnType<typeof this.buildForm>;

  private bSubject!: BehaviorSubject<
    | {
        mappedValueForm: MappedPurchasingDataType;
        validValueForm: boolean;
      }
    | undefined
  >;

  get bSubjectGetter() {
    return this.bSubject.getValue();
  }

  brands!: { id: string; name: string }[];

  models!: { id: string; name: string }[];

  genericNamesClassification!: { id: string; names: INames[] }[];

  life?: GenericExtender<ITimeUnit>[];

  /**
   * Class constructor
   *
   * @param {FormBuilder} formBuilder formBuilder
   */
  constructor(private formBuilder: FormBuilder) {
    this.initForm();
  }

  nextMappedValueForm(value: Partial<PurchasingDataType>, valid: boolean) {
    this.bSubject.next({
      mappedValueForm: purchasingDataPostMapper(value),
      validValueForm: valid
    });
  }

  initForm() {
    this.brands = [];
    this.models = [];
    this.life = [];
    this.formGroup = this.buildForm();
    this.bSubject = new BehaviorSubject<
      | {
          mappedValueForm: MappedPurchasingDataType;
          validValueForm: boolean;
        }
      | undefined
    >(undefined);
    this.nextMappedValueForm(this.formGroup.getRawValue(), this.formGroup.valid);
    return this.formGroup;
  }

  buildForm() {
    const form = this.formBuilder.group({
      genericNameClassificationId: new FormControl<GenericExtender<IGenericName> | null>(null, []),
      descriptionES: new FormControl<string | null>(null, []),
      descriptionPT: new FormControl<string | null>(null, []),
      brand: new FormControl<string | null>(null, []),
      model: new FormControl<string | null>(null, []),
      isLpcExclusive: new FormControl<boolean | null>(false, []),
      isDangerous: new FormControl<boolean | null>(false, []),
      isKit: new FormControl<boolean | null>(false, []),
      technicalDatasheet: new FormControl<string[] | null>([], []),
      life: new FormControl<string | null>(null, []),
      taricCode: new FormControl<string | null>(null, []),
      naceCode: new FormControl<string | null>({ value: null, disabled: true }, []),
      plannedUsefulLife: new FormControl<number | null>(null, []),
      plannedUsefulLifeType: new FormControl<INames[] | null>(null, []),
      //editable
      description: new FormControl<string | null>(null, [
        Validators.maxLength(4000),
        checkHasChangedFormControlValidator
      ]),
      shelfLife: new FormControl<string | null>(null, [checkHasChangedFormControlValidator]),
      plannedUsefulLifeUnitOfMeasureId: new FormControl<GenericExtender<ITimeUnit> | null>(null, [
        checkHasChangedFormControlValidator
      ]),
      madeIn: new FormControl<boolean | null>(false, [checkHasChangedFormControlValidator]),
      //fileNames
      explodedViewFileList: new FormControl<IFileList | null>(null, []),
      technicalDatasheetFileList: new FormControl<IFileList | null>(null, [])
    });
    form.setErrors({ incorrect: true });
    return form;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ENDPOINTS, ENDPOINTS_B2C } from 'app/config/endpoints';
import {
  IAccountantChart,
  IChartsResponse,
  ILastMovementsResponse,
  INeedsListResponse,
  IProductsListResponse,
  IProvisioningChart,
  ITaxChart
} from 'app/repository/models/home.models';
import { NumberNullUndefinedType, NumberStringNullUndefinedType } from 'app/types/global.types';
import { GetProductListRequestBodyType } from 'app/use-cases/services/home-api/home-api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private httpClient: HttpClient) {}

  getTaxCharts() {
    const url: string = `${ENDPOINTS.taxCharts}`;
    return this.httpClient.get<{ data: ITaxChart }>(url);
  }

  getAccountantCharts() {
    const url: string = `${ENDPOINTS.accountantCharts}`;
    return this.httpClient.get<{ data: IAccountantChart }>(url);
  }

  getProvisioningCharts() {
    const url: string = `${ENDPOINTS.provisioningCharts}`;
    return this.httpClient.get<{ data: IProvisioningChart }>(url);
  }

  getChartsHome() {
    const url: string = `${ENDPOINTS.chartsHome}`;
    return this.httpClient.get<IChartsResponse>(url);
  }

  getNeedsList(
    search: string,
    pageNumber: number,
    pageSize: number,
    statusId?: NumberNullUndefinedType,
    costCenterId?: NumberStringNullUndefinedType,
    isCovered?: boolean
  ) {
    let url: string = `${ENDPOINTS.needsList}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (typeof statusId === 'number') {
      url = url + `&statusId=${statusId}`;
    }
    if (typeof costCenterId === 'string' || typeof costCenterId === 'number') {
      url = url + `&costCenterId=${costCenterId}`;
    }
    if (typeof isCovered === 'boolean') {
      url = url + `&isCovered=${isCovered}`;
    }
    const body = {};
    return this.httpClient.post<INeedsListResponse>(url, body);
  }

  getProductsList(body: Partial<GetProductListRequestBodyType>, isSupplierExt: boolean) {
    const bodyPost = {
      pageNumber: body.pageNumber,
      pageSize: body.pageSize,
      search: body.search,
      statusId: body.statusId,
      ...(isSupplierExt
        ? {
            missingSupplierInfo: body.missingSupplierInfo
          }
        : {
            costCenterId: body.costCenterId,
            supplierId: body.supplierId,
            productWithoutNegotiation: body.productWithoutNegotiation,
            onlyMMFF: body.onlyMMFF,
            excludeVariableArticleId: body.excludeVariableArticleId
          })
    };

    const endpoint = isSupplierExt ? `${ENDPOINTS_B2C.productsList}` : `${ENDPOINTS.productsList}`;
    return this.httpClient.post<IProductsListResponse>(endpoint, bodyPost);
  }

  getTaxVariableArticles(
    search: string,
    pageNumber: number,
    pageSize: number,
    statusId?: NumberNullUndefinedType,
    missingTaxInfo?: boolean
  ) {
    const url: string = `${ENDPOINTS.taxVariableArticles}`;
    const body = {
      pageNumber,
      pageSize,
      search,
      statusId,
      missingTaxInfo: missingTaxInfo ?? false
    };
    return this.httpClient.post<IProductsListResponse>(url, body);
  }

  getAccountantVariableArticles(
    search: string,
    pageNumber: number,
    pageSize: number,
    statusId?: NumberNullUndefinedType,
    missingAccountingInfo?: boolean
  ) {
    const url: string = `${ENDPOINTS.accountantVariableArticles}`;
    const body = {
      pageNumber,
      pageSize,
      search,
      statusId,
      missingAccountingInfo: missingAccountingInfo ?? false
    };
    return this.httpClient.post<IProductsListResponse>(url, body);
  }

  getProvisioningVariableArticles(
    search: string,
    pageNumber: number,
    pageSize: number,
    statusId?: NumberNullUndefinedType,
    pending?: boolean
  ) {
    const url: string = `${ENDPOINTS.provisioningVariableArticles}`;
    const body = {
      pageNumber,
      pageSize,
      search,
      statusId,
      pending: pending ?? false
    };
    return this.httpClient.post<IProductsListResponse>(url, body);
  }

  getProvisioningLastMovements(pageNumber: number, pageSize: number) {
    const url: string = `${ENDPOINTS.provisioningLatestMovementsList}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const body = {};
    return this.httpClient.get<ILastMovementsResponse>(url, body);
  }

  getLastMovements(pageNumber: number, pageSize: number) {
    const url: string = `${ENDPOINTS.latestMovementsList}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const body = {};
    return this.httpClient.get<ILastMovementsResponse>(url, body);
  }
}

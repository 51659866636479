import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ENDPOINTS, ENDPOINTS_B2C } from 'app/config/endpoints';
import { IImageProfile } from 'app/repository/models/home.models';
import {
  IArticleTypesResponse,
  IBudgetImplementationTypesResponse,
  IBusinessCenterSubtypesResponse,
  IChargeOfMaintenance,
  ICodeGeneratorResponse,
  ICostCenterTypesResponse,
  ICostCentersResponse,
  INodeType,
  IPurchasingManagersResponse,
  IStatusesResponse
} from 'app/repository/models/need.model';
import {
  IBrand,
  ICurrencyTypesResponse,
  IGenericNameClassifications,
  IModel,
  ISupplierCurrencyResponse,
  ISuppliersResponse,
  ITimeUnit
} from 'app/repository/models/product.models';

/**
 * CommonService
 */
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  /**
   * Class constructor
   *
   * @param {HttpClient} httpClient httpClient
   */
  constructor(private httpClient: HttpClient) {}

  getPurchasingManagers(hierarchyNodeId: string) {
    const url: string = `${ENDPOINTS.purchasingManagers}?hierarchyNodeId=${hierarchyNodeId}`;
    return this.httpClient.get<IPurchasingManagersResponse>(url);
  }

  getBusinessCenterSubtypes() {
    const url: string = `${ENDPOINTS.businessCenterSubtypes}`;
    return this.httpClient.get<IBusinessCenterSubtypesResponse>(url);
  }

  getArticleTypes(isSupplierExt: boolean, _hierarchyNodeId?: string) {
    const endpoint = isSupplierExt ? `${ENDPOINTS_B2C.articleTypes}` : `${ENDPOINTS.articleTypes}`;

    return this.httpClient.get<IArticleTypesResponse>(endpoint);
  }
  getCostCenterTypes() {
    const url: string = `${ENDPOINTS.costCenterTypes}`;
    return this.httpClient.get<ICostCenterTypesResponse>(url);
  }

  getBudgetImplementationTypesNeed(hierarchyNodeId: string) {
    const url: string = `${ENDPOINTS.budgetImplementationTypesNeed(hierarchyNodeId)}`;
    return this.httpClient.get<IBudgetImplementationTypesResponse>(url);
  }

  getBudgetImplementationTypes(needId?: string) {
    let url: string = `${ENDPOINTS.budgetImplementationTypes}`;
    if (needId) {
      url = url + `?needId=${needId}`;
    }
    return this.httpClient.get<IBudgetImplementationTypesResponse>(url);
  }

  getStatuses(statusType: number, isSupplierExt: boolean) {
    const endpoint = isSupplierExt ? `${ENDPOINTS_B2C.statuses(statusType)}` : `${ENDPOINTS.statuses(statusType)}`;
    return this.httpClient.get<IStatusesResponse>(endpoint);
  }

  getCostCenters() {
    const url: string = `${ENDPOINTS.costCenters}`;
    return this.httpClient.get<ICostCentersResponse>(url);
  }

  getSuppliers(search: string, pageNumber?: number, pageSize?: number) {
    const url: string = `${ENDPOINTS.suppliers(search, pageNumber, pageSize)}`;
    return this.httpClient.get<ISuppliersResponse>(url);
  }

  /**
   * codeGenerator
   *
   * @param {string} typeId :1 = needs, :2 articles
   * @param {string} codeAmount :1
   * @returns {object} res res
   */
  codeGenerator(typeId: string, codeAmount: number) {
    let url: string = `${ENDPOINTS.codeGenerator}/${typeId}`;
    if (codeAmount) {
      url = url + `?codeAmount=${codeAmount}`;
    }
    return this.httpClient.post<ICodeGeneratorResponse>(url, {});
  }

  getBrands(isSupplierExt: boolean) {
    const endpoint = isSupplierExt ? `${ENDPOINTS_B2C.brands}` : `${ENDPOINTS.brands}`;

    return this.httpClient.get<IBrand>(endpoint);
  }

  createBrand(brandName: string) {
    const url: string = `${ENDPOINTS.brands}` + `?brandName=${brandName}`;
    return this.httpClient.post<object>(url, {});
  }

  createWhatIsIt(newWhatIsIt: string, hierarchyNodeId: string) {
    const url: string =
      `${ENDPOINTS.genericNameClassifications}` + `?genericName=${newWhatIsIt}&hierarchyNodeId=${hierarchyNodeId}`;
    return this.httpClient.post<object>(url, {});
  }

  getModels(brandId: number, isSupplierExt: boolean) {
    const endpoint = isSupplierExt ? `${ENDPOINTS_B2C.models(brandId)}` : `${ENDPOINTS.models(brandId)}`;
    return this.httpClient.get<IModel>(endpoint);
  }

  createModel(brandId: number, modelName: string) {
    const url: string = `${ENDPOINTS.models(brandId)}` + `?modelName=${modelName}`;
    return this.httpClient.post<object>(url, {});
  }

  getCurrency() {
    const url: string = `${ENDPOINTS.currency}`;
    return this.httpClient.get<ICurrencyTypesResponse>(url);
  }

  getCurrencySupplier(supplierId: string) {
    const url: string = `${ENDPOINTS.currencySupplier(supplierId)}`;
    return this.httpClient.get<ISupplierCurrencyResponse>(url);
  }

  getTimeUnits(isSupplierExt: boolean) {
    const endpoint = isSupplierExt ? `${ENDPOINTS_B2C.timeUnits}` : `${ENDPOINTS.timeUnits}`;

    return this.httpClient.get<{ data: ITimeUnit[] }>(endpoint);
  }

  getNodeType(hierarchyNodeId: string) {
    const url: string = `${ENDPOINTS.getNodeType(hierarchyNodeId)}`;

    return this.httpClient.get<INodeType>(url);
  }

  getImageProfile() {
    const url: string = ENDPOINTS.imageProfile;

    return this.httpClient.get<IImageProfile>(url);
  }

  getChargeOfMaintenance() {
    const url: string = ENDPOINTS.chargeOfMaintenance;

    return this.httpClient.get<{ data: IChargeOfMaintenance[] }>(url);
  }

  getGenericNameClassifications(hierarchyNodeId: string, variableArticleId: string, isSupplierExt: boolean) {
    const endpoint = isSupplierExt
      ? `${ENDPOINTS_B2C.genericNameClassifications}`
      : `${ENDPOINTS.genericNameClassifications}`;

    const url: string = endpoint + `?hierarchyNodeId=${hierarchyNodeId}&variableArticleId=${variableArticleId}`;

    return this.httpClient.get<IGenericNameClassifications>(url);
  }
}

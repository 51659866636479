import { GenericExtender } from '@interfaces/generic-extender.interface';

/**
 * @template T
 * @param {T} x x
 * @returns {GenericExtender<T>} res res
 */
export function makeGenericExtender<T>(x: T): GenericExtender<T> {
  x = recursivePatchSameLevelTranslations(x);
  return Object.assign(x as unknown as object) as unknown as GenericExtender<T>;
}

/**
 * @template T
 * @param {ReturnType<typeof isTranslationType>} elementList elementList
 * @returns {boolean} res res
 */
function isTranslationType<T>(elementList: T[Extract<keyof T, string>]) {
  if (
    !(
      elementList &&
      typeof elementList === 'object' &&
      Object.prototype.toString.call(elementList) === '[object Object]'
    )
  ) {
    return false;
  }
  const entry = elementList as unknown as {
    [s: string]: string;
  };
  return (
    Object.keys(entry).length === 2 &&
    Object.keys(entry).some((k) => {
      if (
        !(entry[k] && typeof entry[k] === 'string' && Object.prototype.toString.call(entry[k]) === '[object String]')
      ) {
        return false;
      }
      const regex = /^[a-z]{2}-[A-Z]{2}$/;
      const regex2 = /^[a-z]{2}_[A-Z]{2}$/;
      if (regex.test(entry[k]) || regex2.test(entry[k])) {
        return true;
      }
      return false;
    }) &&
    Object.values(entry).every(
      (e) => e && typeof e === 'string' && Object.prototype.toString.call(e) === '[object String]'
    )
  );
}

/**
 * @template T
 * @param {ReturnType<typeof isObjectProperty>} property property
 * @returns {boolean} res res
 */
export function isObjectProperty<T>(property: T /* [Extract<keyof T, string>] */) {
  return property && typeof property === 'object' && Object.prototype.toString.call(property) === '[object Object]';
}

/**
 * @template T
 * @param {ReturnType<typeof isArrayProperty>} property property
 * @returns {boolean} res res
 */
export function isArrayProperty<T>(property: T /* [Extract<keyof T, string>] */) {
  return property && typeof property === 'object' && Object.prototype.toString.call(property) === '[object Array]';
}

/**
 * @template T
 * @param {ReturnType<typeof isArrayProperty>} property property
 * @returns {boolean} res res
 */
export function isDateProperty<T>(property: T /* [Extract<keyof T, string>] */) {
  return property && typeof property === 'object' && Object.prototype.toString.call(property) === '[object Date]';
}

/**
 * @template T
 * @param {ReturnType<typeof isEveryItemTranslationType>} ls ls
 * @returns {boolean} res res
 */
function isEveryItemTranslationType<T>(ls: T[Extract<keyof T, string>]) {
  return (ls as unknown as (typeof ls)[]).every(isTranslationType);
}

/**
 * @template T
 * @param {T} obj obj
 * @param {ReturnType<typeof patchSameLevelTranslations>} ls ls
 */
function patchSameLevelTranslations<T>(obj: T, ls: T[Extract<keyof T, string>]) {
  (ls as unknown as (typeof ls)[]).forEach((entry) => {
    const firstKey = Object.entries(
      entry as unknown as {
        [s: string]: unknown;
      }
    ).filter(([_, value]: [string, unknown]) => {
      const regex = /^[a-z]{2}-[A-Z]{2}$/;
      const regex2 = /^[a-z]{2}_[A-Z]{2}$/;
      if (regex.test(value as string) || regex2.test(value as string)) {
        return true;
      }
      return false;
    })[0][0] as unknown as keyof typeof entry;

    const secondKey = Object.keys(
      entry as unknown as {
        [s: string]: unknown;
      }
    ).filter((kk) => kk !== firstKey)[0] as unknown as keyof typeof entry;

    let kkk = entry[firstKey] as unknown as keyof typeof obj;
    kkk = (kkk as string).split('-').join('_') as unknown as keyof typeof obj;
    /* obj[kkk] = entry[secondKey] as unknown as T[keyof T]; */
    obj[kkk] = entry[secondKey] as unknown as T[keyof T];
    kkk = (kkk as string).split('_').join('-') as unknown as keyof typeof obj;
    obj[kkk] = entry[secondKey] as unknown as T[keyof T];
  });
}

/**
 * @template T
 * @param {T} obj obj
 * @returns {T} res res
 */
function recursivePatchSameLevelTranslations<T>(obj: T) {
  for (const k in obj) {
    if (isObjectProperty(obj[k])) {
      recursivePatchSameLevelTranslations(obj[k]);
    } else if (isArrayProperty(obj[k])) {
      const ls = obj[k];

      if (isEveryItemTranslationType(ls)) {
        patchSameLevelTranslations(obj, ls);
      } else {
        (ls as unknown as (typeof ls)[]).forEach((e) => recursivePatchSameLevelTranslations(e));
      }
    } else {
      continue;
    }
  }
  return obj;
}

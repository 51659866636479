import { Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subject, catchError, filter, of, takeUntil, tap } from 'rxjs';

import { APP_CONFIG } from '@constants';
import { LanguageSelectorService } from '@services/language-selector.service';
import { emptyString } from 'app/constants/global.constants';
import { CommonApiService } from 'app/use-cases/services/common-api/common-api.service';
import { UtilsService } from 'app/use-cases/services/utils/utils.service';

import { MContextMenuPositionX, MContextMenuPositionY } from '@mercadona/components/context-menu';
import { MDialogConfirmData, MDialogService } from '@mercadona/components/dialog';
import { MAuthService, MAuthenticationResult } from '@mercadona/core/auth';
import { MSupportedLanguages, MTranslateService } from '@mercadona/core/translate';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * All user permissions
   */
  allUserPermissions: string[] = [];

  /**
   * User name displayed in header
   */
  userName: string = emptyString;
  imageProfile: string = emptyString;

  /**
   * Subject to unsubscribe
   */
  unsubscribe$ = new Subject<void>();

  posX: MContextMenuPositionX = 'after';
  posY: MContextMenuPositionY = 'bottom';

  languages: { title: string; selected: boolean }[] = this.languageSelectorService.languages;
  isSupplierExt: boolean = false;

  /**
   * Class constructor
   *
   * @param {MTranslateService} translateService service
   * @param {MAuthService} mAuthService mAuthService
   * @param {MDialogService} dialogService dialogService
   * @param {LanguageSelectorService} languageSelectorService languageSelectorService
   * @param {UtilsService} utils utils service
   * @param {CommonApiService} commonApiService common API service
   */
  constructor(
    private translateService: MTranslateService,
    private mAuthService: MAuthService,
    private dialogService: MDialogService,
    private languageSelectorService: LanguageSelectorService,
    public utils: UtilsService,
    private commonApiService: CommonApiService
  ) {
    this.selectLanguage(this.languages.filter((e) => e.selected)[0]);
  }

  /**
   * Initialization: retrieve username from auth token, languages list and screen size
   */
  ngOnInit(): void {
    this.isSupplierExt = this.utils.getLocationPathname().includes('/cdv90/supplier') ? true : false;

    const systemLanguage = navigator.language.split('-')[0] as MSupportedLanguages;
    if (APP_CONFIG.language.availableLanguages.includes(systemLanguage)) {
      this.translateService.setActiveLang(systemLanguage);
    }
    this.tokenSubscription();
    if (!this.isSupplierExt) {
      this.getImageProfile();
    }
  }

  /**
   * Destroy: unsubscribe from subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  tokenSubscription(): void {
    this.mAuthService.token$
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe((response: MAuthenticationResult | null) => {
        const token: Record<string, never> | undefined = response?.accessTokenClaims;
        this.userName = this.getUserName(token);
        this.mAuthService
          .getPermissions()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((p) => {
            this.allUserPermissions = [...p];
          });
      });
  }

  getUserName(token: Record<string, never> | undefined): string {
    return `${token?.given_name ? (token.given_name as string) : emptyString} ${
      token?.family_name ? (token?.family_name as string) : emptyString
    }`;
  }

  /**
   * Handle logout
   */
  async handleLogoutButtonClick() {
    const dialogConfirmDialogData: MDialogConfirmData = {
      message: this.translateService.translate('header.logout.confirmCloseSession'),
      confirmButtonLabel: await this.translateService.translate('header.logout.buttons.confirm'),
      cancelButtonLabel: await this.translateService.translate('header.logout.buttons.cancel'),
      state: 'destructive'
    };

    this.dialogService
      .openConfirm(dialogConfirmDialogData)
      .afterClosed()
      .pipe(filter((response: boolean) => response))
      .subscribe(() => {
        this.mAuthService.logout();
      });
  }

  selectLanguage(language: { title: string; selected: boolean }) {
    this.languages.filter((e) => e.selected)[0].selected = false;
    language.selected = true;
    this.languageSelectorService.nextValue(language.title);
    this.translateService.setActiveLang(language.title.split('_')[0]);
  }

  trackByFn = (_index: number, item: unknown) => item;

  getImageProfile() {
    this.commonApiService
      .getImageProfile()
      .pipe(
        tap((res) => (this.imageProfile = res.data.employeePhotoUrl)),
        catchError(() => of(EMPTY))
      )
      .subscribe();
  }

  get isProviderB2C() {
    return this.allUserPermissions.some((p) => p.includes('ZCDV01'));
  }
}
